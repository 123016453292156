export class RatingSearchModel {
    constructor(){
        this.IsApproved=false;
        this.Sort='';
        this.SearchParameter='';
        this.UserRating=0;
        this.UserRelation='';
        this.UserEmail='';
        this.PageNumber=0;
        this.OrderDirection=OrderDirection.Ascending;
    }
    public OrderDirection:OrderDirection;
    public Sort:string;
    public PageNumber:number;
    public PageLimit:number;
    public SearchParameter:string;
    public IsApproved:boolean;
    public UserRating:number;
    public UserRelation:string;
    public UserEmail:string;
}

export enum OrderDirection{
    Ascending,
    Descending
}