import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
@Directive({
  selector: '[appPasswordvalidation]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordvalidationDirective, multi: true }]
})
export class PasswordvalidationDirective {
  @Input() appPasswordvalidation: string;
  constructor() {
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    const password = control.parent.get(this.appPasswordvalidation);

    if (password.value !== control.value) {
      return { notEqule: true };
    }
    return null;
  }
}
