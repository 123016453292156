import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { Download2Component } from './components/download2/download2.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ForgotPasswordStep2Component } from './components/forgot-password-step2/forgot-password-step2.component';
import { AfterdownloadComponent } from './components/afterdownload/afterdownload.component';
import { DownloadComponent } from './components/download/download.component';



const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Login'
    },
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch:'full'

      },
      {
        path: 'login',
        component: LoginComponent,
        data: {
          title: 'Login'
        }
      },
      {
        path: 'signup',
        component: Download2Component,
        data: {
          title: 'Signup'
        }
      },
      {
        path: 'newpassword',
        component: NewPasswordComponent,
        data: {
          title: 'New Password'
        }
      },
      {
        path: 'forgotpassword',
        component: ForgotPasswordComponent,
        data: {
          title: 'Forgot Password'
        }
      },
      {
        path: 'forgotpasswordstep2',
        component: ForgotPasswordStep2Component,
        data: {
          title: 'Forgot Password'
        }
      },
      {
        path: 'afterdownload',
        component: AfterdownloadComponent,
        data: {
          title: 'After download'
        }
      },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
