import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'singleDash'
})
export class SingleDashPipe implements PipeTransform {

  transform(value: number): any {
    if(value==undefined) return value;
    return value.toString().replace(/(\d{3})(\d{3})/, "$1-$2");
  }

}
