import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { QuestionModel } from '../../models/questionans/questionmodel';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubjectQuetionService {
  private quesApiUrl: string = environment.apiUrl + '/SubjectQuetion';
  constructor(private http: HttpClient) { }

  getQuestion(languageId, RegistrationId): Observable<QuestionModel> {
    const reqHeader = new HttpHeaders({ languageId, RegistrationId });
    return this.http.get<QuestionModel>(this.quesApiUrl, { headers: reqHeader }).pipe(
      tap(() => { }));
  }
}
