import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LinksAndMeta } from 'src/app/seo/Model/linksandmeta.model';
import { SEOService } from 'src/app/seo/services/seo.service';
import { SEOdetailService } from 'src/app/seo/services/seodetail.service';
declare var document: any
@Component({
  selector: 'app-afterdownload',
  templateUrl: './afterdownload.component.html',
  styleUrls: ['./afterdownload.component.css']
})
export class AfterdownloadComponent implements AfterViewInit {
  private thisPageSeoDetail: LinksAndMeta;
  constructor(private router: Router, private elem: ElementRef,private seo: SEOService,
    private seoDetail: SEOdetailService) { }

  ngOninit(){
    
    this.seoDetail.getThisRouteSEODetail('downloadAfterDownload')
    .subscribe(response => {
      if(response)
      {
        // console.log(response);
        this.thisPageSeoDetail = response;
        this.seo.updateTitle(this.thisPageSeoDetail.PageTitle);
        this.seo.updateTag({name: 'meta', content: this.thisPageSeoDetail.MetaData});
        this.seo.updateTag({name: 'keywords', content: this.thisPageSeoDetail.Keyword});
      }
      else{
        console.log("downloadAfterDownload SEO Response is null");
      }
    });;
  }

  ngAfterViewInit() {
    // this.downloadModal();
    //let p = this.elem.nativeElement.querySelector(".form-main");
    //var element = document.getElementsByClassName("form-main");
    //element[0].classList.remove("form-main");
  }

  @ViewChild("DownloadModal", { static: false }) MoldDbModal: ModalDirective;
  downloadModal() {
    try {
      this.MoldDbModal.show();
      // let modal = this.elem.nativeElement.querySelector(".modal-backdrop.show");
      // modal.css('opacity', 0);
      // var modalDialog = this.elem.nativeElement.querySelector(".modal-dialog");
      // modalDialog.css("margin-top", Math.max(0, ($(window).height() - modalDialog.height()) / 2));
    } catch (e) { }
  }

  onModalShown() {
    // document.getElementsByClassName('modal-backdrop')[0].style.opacity = 0
    //  this.elem.nativeElement.querySelector(".modal-backdrop").style.opacity=0;
    // modal.css('opacity', 0);
    setTimeout(() => {
      // document.getElementsByClassName('modal-backdrop')[0].style.opacity = 0
      // var div = document.getElementsByClassName('modal-backdrop')[0];
      // div.parentNode.removeChild(div);
      this.MoldDbModal.hide();
      this.closeModal();
      
    }, 100);
  }

  closeModal() {
    this.router.navigateByUrl('/home')
  }
  ngOnDestroy(){
    this.seo.removeTag("meta");
    this.seo.removeTag("keywords");
  }
}
