import { AnswerModel } from './answermodel';
import { VerbModel } from './verbmodel';

export class QuestionModel {
    constructor() {
      this.AnswerEvaModels[0] = new AnswerModel();
    }
    public Question: string;
    public QuestionId: number;
    public SubjectId: number;
    public SubText: string;
    // tslint:disable-next-line: variable-name
    public HelpText_Hebrew: string;
    public HelpText: string;
    public AnswerEvaModels: AnswerModel[] = [];
    public VerbModels: VerbModel[] = [];
    public NoOfAttemptQuestion: number;
    public RuleId: number;
    public TotalQuetion: number;
  }
