import { Component, OnInit, ViewChild } from '@angular/core';
import { Usermodel } from '../../models/usermodel';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataservicesService } from 'src/app/shared/services/dataservice.service';
import { AlertmodalComponent } from 'src/app/shared/components/alertmodal/alertmodal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { LinksAndMeta } from 'src/app/seo/Model/linksandmeta.model';
import { SEOService } from 'src/app/seo/services/seo.service';
import { SEOdetailService } from 'src/app/seo/services/seodetail.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {
  UserModel: Usermodel;
  modalRef: BsModalRef;
  private userId;
  private thisPageSeoDetail: LinksAndMeta;


  @ViewChild(NgForm, {static: false}) newPassForm: NgForm;

  constructor( private activatedRoute: ActivatedRoute, private userService: UserService, private Spinner: NgxSpinnerService,
               private modalService: BsModalService, private sharedService: DataservicesService,
               private router: Router,private seo: SEOService,
               private seoDetail: SEOdetailService) { }

  ngOnInit() {
    this.UserModel = new Usermodel();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.userId = params.id;
      if (this.userId === null && this.userId === undefined) {
        this.router.navigate(['/download/forgotpassword']);
      }
      this.UserModel.UserId = this.userId;
    });

    this.seoDetail.getThisRouteSEODetail('downloadNewPassword')
    .subscribe(response => {
      if(response)
      {
        // console.log(response);
        this.thisPageSeoDetail = response;
        this.seo.updateTitle(this.thisPageSeoDetail.PageTitle);
        this.seo.updateTag({name: 'meta', content: this.thisPageSeoDetail.MetaData});
        this.seo.updateTag({name: 'keywords', content: this.thisPageSeoDetail.Keyword});
      }
      else{
        console.log("downloadNewPassword SEO Response is null");
      }
    });;
  }

  updateUserPass() {
    this.Spinner.show();
    this.userService.updateUserPass(this.UserModel).subscribe(res => {
      this.Spinner.hide();
      if (res.IsSuccessfully) {
        this.sharedService.add('New password', res.Message);
        this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
        this.clearFormData();
        this.router.navigate(['/download/login']);
      } else {
        this.UserModel.UserPassword = null;
        this.sharedService.add('New password', res.Message);
        this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
      }
    }, (err: HttpErrorResponse) => {
      this.Spinner.hide();
      this.sharedService.add('New password', 'Server error!!!');
      this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
      console.log(err);
    });
  }

  clearFormData(): void {
    this.UserModel = new Usermodel();
    if (this.newPassForm) {
      this.newPassForm.resetForm(this.UserModel);
    }
  }
  ngOnDestroy(){
    this.seo.removeTag("meta");
    this.seo.removeTag("keywords");
  }
}
