import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm, NgModel } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { GTMService } from "src/app/core/services/gtm.service";
import { Usermodel } from "../../models/usermodel";
import { DownloadComponent } from "../download/download.component";
declare var $: any;
@Component({
  selector: "app-download2",
  templateUrl: "./download2.component.html",
  styleUrls: ["./download2.component.css"],
})
export class Download2Component implements OnInit {
  modalRef: BsModalRef;
  UserModel: Usermodel;
  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private gtmService: GTMService
  ) {}

  ngOnInit() {
    this.UserModel = new Usermodel();
  }
  @ViewChild(NgForm, { static: false }) loginForm: NgForm;
  @ViewChild("emailaddress", { static: true }) email: NgModel;

  closeModal() {
    this.bsModalRef.hide();
    this.gtmService.pushEventTag(
      "Download2 modal unloaded",
      "Download2",
      "click",
      "User close Download2 dialog"
    );
  }

  openDownloadApp() {
    this.closeModal();
    // console.log(this.UserModel);
    this.modalRef = this.modalService.show(DownloadComponent, {
      backdrop: "static",
      keyboard: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered max-598",
      initialState: this.UserModel,
    });
    this.gtmService.pushEventTag(
      "Download modal loaded",
      "Download",
      "click",
      "User opened Download modal"
    );
    this.modalRef.content.userModel = this.UserModel;
  }

  focusFunction() {
    $(".input-inside").addClass("filled");
    $(".input-inside label").css("top", "0%");
  }

  focusOutFunction(emailaddress) {
    if (emailaddress.value) {
      return;
    } else if (!emailaddress.valid && !emailaddress.pristine) {
      $(".input-inside").removeClass("filled");
      $(".input-inside label").css("top", "35%");
    } else {
      $(".input-inside").removeClass("filled");
      $(".input-inside label").css("top", "50%");
    }
  }
}
