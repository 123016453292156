import { Component, OnInit, ViewChild, AfterViewInit, Input} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { DataservicesService } from 'src/app/shared/services/dataservice.service';
import { DownloadService } from '../../services/download/download.service';
import { Usermodel } from '../../models/usermodel';
import { AlertmodalComponent } from 'src/app/shared/components/alertmodal/alertmodal.component';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GTMService } from 'src/app/core/services/gtm.service';
declare var $: any;
declare var document: any;

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit, AfterViewInit {

  constructor(private Spinner: NgxSpinnerService,
              private sharedService: DataservicesService,
              private downloadService: DownloadService, private router: Router,public bsModalRef: BsModalRef,private modalService: BsModalService,
              private gtmService:GTMService
              ) { }
 
  modalRef: BsModalRef;
  percentDone = 0;
  @Input()
  Email;

  UserModel:Usermodel;
  ngOnInit() {
    this.Spinner.show();
    this.percentDone = 0;
    this.UserModel = new Usermodel();
    console.log(this.Email);
  }

  ngAfterViewInit(): void {
    this.downloadExe();
  }


  closeModal() {
    this.bsModalRef.hide();
    this.gtmService.pushEventTag('Download2 modal unloaded','Download2','click','User close Download2 dialog');
  }
  // downloadModal() {
  //   try {
  //     this.MoldDbModal.show();
  //   } catch (e) { }
  // }

  // onModalShown() {
  //   document.getElementsByClassName('modal-backdrop')[0].style.opacity = 0;
  // }

  downloadExe() {
    this.Spinner.hide();
    //using dummy email id
    this.UserModel.Email = this.Email;
          this.downloadService.downloadExe(this.UserModel).subscribe(response => {
          this.percentDone = this.downloadService.getEventMessage(response) ? this.downloadService.getEventMessage(response) : 0;
          // this.downloadModal();
          if (response.type === HttpEventType.Response) {
            this.Spinner.hide();
            const file = new File([response.body], environment.EXEFileName, { type: 'application/octet-stream' });
            saveAs(file);
            // this.MoldDbModal.hide();
            this.closeModal();
            this.router.navigateByUrl('/home');
          }
        }, (err: HttpErrorResponse) => {
          this.Spinner.hide();
          // this.MoldDbModal.hide();
          this.sharedService.add('Download', 'Server error!!');
          this.closeModal();
          this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
          console.log(err);
        });
  }
}
