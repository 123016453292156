import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EvaluationRoutingModule } from './evaluation-routing.module';
import { UserRegistrationComponent } from './components/user-registration/user-registration.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from 'src/app/shared/modules/shared/shared.module';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { QuestionAnsComponent } from './components/question-ans/question-ans.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TimeoutComponent } from './modal/timeout/timeout.component';
import { StopExerciseComponent } from './modal/stop-exercise/stop-exercise.component';
import { SubjectQuestionComponent } from './components/subject-question/subject-question.component';
import { FinishComponent } from './modal/finish/finish.component';
import { ReportComponent } from './components/report/report.component';
import { SubmitIfValidDirective } from 'src/app/shared/directives/formsubmitval/submit-if-valid.directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TrimStr } from 'src/app/shared/pipes/trim.pipe';
import { SingleDashPipe } from 'src/app/shared/pipes/single-dash.pipe';

@NgModule({
  declarations: [QuestionAnsComponent, TimeoutComponent, StopExerciseComponent,
    SubjectQuestionComponent,
    FinishComponent, ReportComponent,  
    TrimStr, SingleDashPipe
  ],
  imports: [
    CommonModule,
    EvaluationRoutingModule,
    FormsModule, ReactiveFormsModule,
    SharedModule,
    NgxSpinnerModule,
    ProgressbarModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SharedModule
  ],
  exports: [
  ],
  providers: [
    BsModalService,
    BsModalRef
  ],
  entryComponents: [
    StopExerciseComponent,
    TimeoutComponent,
    FinishComponent
  ],
})
export class EvaluationModule { }
