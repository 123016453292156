import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { Usermodel } from '../../models/usermodel';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  private downloadApiUrl: string = environment.apiUrl + '/download';
  constructor(private http: HttpClient) { }

  downloadExe(user: Usermodel): Observable<any> {
     const reqHeader = new HttpHeaders({ userId: user.Email, password : '' });
     return this.http.get(this.downloadApiUrl, { headers: reqHeader, reportProgress: true, responseType: 'blob', observe: 'events'}).pipe(
      tap((event) => {}, catchError(this.handleError('getUser', []))) );
  }

  public getEventMessage(event: HttpEvent<any>) {
    let percentDone = 0;
    switch (event.type) {
      case HttpEventType.DownloadProgress:
        percentDone = Math.round(100 * event.loaded / event.total);
        return percentDone;
        case HttpEventType.Response:
        return 100;
      default:
        console.log(`File surprising upload event`);
        break;
    }
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
