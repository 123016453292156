import { Component, OnInit } from '@angular/core';
import { LinksAndMeta } from 'src/app/seo/Model/linksandmeta.model';
import { SEOService } from 'src/app/seo/services/seo.service';
import { SEOdetailService } from 'src/app/seo/services/seodetail.service';

@Component({
  selector: 'app-evaluation-default-layout',
  templateUrl: './evaluation-default-layout.component.html',
  styleUrls: ['./evaluation-default-layout.component.css']
})
export class EvaluationDefaultLayoutComponent implements OnInit {
  private thisPageSeoDetail: LinksAndMeta;
  constructor(private seo: SEOService,
    private seoDetail: SEOdetailService) { }

  ngOnInit() {
    this.seoDetail.getThisRouteSEODetail('evaluation')
    .subscribe(response => {
      if(response)
      {
        // console.log(response);
        this.thisPageSeoDetail = response;
        this.seo.updateTitle(this.thisPageSeoDetail.PageTitle);
        this.seo.updateTag({name: 'meta', content: this.thisPageSeoDetail.MetaData});
        this.seo.updateTag({name: 'keywords', content: this.thisPageSeoDetail.Keyword});
      }
      else{
        console.log("evaluation SEO Response is null");
      }
    });;
  }

  ngOnDestroy(){
    this.seo.removeTag("meta");
    this.seo.removeTag("keywords");
  }
}
