import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Usermodel } from '../../models/usermodel';
import { UserService } from '../../services/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataservicesService } from 'src/app/shared/services/dataservice.service';
import { AlertmodalComponent } from 'src/app/shared/components/alertmodal/alertmodal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm, NgModel } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LinksAndMeta } from 'src/app/seo/Model/linksandmeta.model';
import { SEOService } from 'src/app/seo/services/seo.service';
import { SEOdetailService } from 'src/app/seo/services/seodetail.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  UserModel: Usermodel;
  modalRef: BsModalRef;
  private userId;

  @ViewChild(NgForm, { static: false }) forgotForm: NgForm;
  // @ViewChild('verify', { static: true }) verify: ElementRef;
  private thisPageSeoDetail: LinksAndMeta;

  constructor(private userService: UserService, private Spinner: NgxSpinnerService,
              private modalService: BsModalService, private sharedService: DataservicesService,
              private router: Router, private activatedRoute: ActivatedRoute,private seo: SEOService,
              private seoDetail: SEOdetailService) { }

  ngOnInit() {
    this.UserModel = new Usermodel();
    this.activatedRoute.paramMap.subscribe((params) => {
      this.userId = params.get('id');
      if (this.userId === null && this.userId === undefined) {
        this.router.navigate(['/download/login']);
      }
      this.UserModel.UserId = this.userId;
      // this.verify.nativeElement.focus();
    });

    this.seoDetail.getThisRouteSEODetail('downloadForgotPassword')
    .subscribe(response => {
      if(response)
      {
        // console.log(response);
        this.thisPageSeoDetail = response;
        this.seo.updateTitle(this.thisPageSeoDetail.PageTitle);
        this.seo.updateTag({name: 'meta', content: this.thisPageSeoDetail.MetaData});
        this.seo.updateTag({name: 'keywords', content: this.thisPageSeoDetail.Keyword});
      }
      else{
        console.log("downloadForgotPassword SEO Response is null");
      }
    });;
  }

  sendCodeEmail() {
    this.Spinner.show();
    this.userService.sendCode(this.UserModel.UserId).subscribe(res => {
      this.Spinner.hide();
      // if (res.IsSuccessfully) {
      //   this.sharedService.add('Reset code', res.Message);
      //   this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
      // } else {
      //   this.UserModel.UserPassword = null;
      //   this.sharedService.add('Reset code', res.Message);
      //   this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
      // }
      if (res.IsSuccessfully) {
        this.router.navigate(['/download/forgotpasswordstep2'], {
          queryParams: { id: this.UserModel.UserId },
          skipLocationChange: true
        });
        this.sharedService.add('Reset code', res.Message);
        this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
      } else {
        this.UserModel.UserPassword = null;
        this.sharedService.add('Reset code', res.Message);
        this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
      }
    }, (err: HttpErrorResponse) => {
      this.Spinner.hide();
      this.sharedService.add('Login', 'Server error!!!');
      this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
      console.log('Error =>>' + err);
    });
  }


  verifyPassCode() {
    this.Spinner.show();
    this.userService.verifyPassCode(this.UserModel.UserId, this.UserModel.ResetCode).subscribe(res => {
      this.Spinner.hide();
      if (res.IsSuccessfully) {
        this.router.navigate(['/download/newpassword', { id: this.UserModel.UserId }]);
        this.clearFormData();
      } else {
        this.UserModel.UserPassword = null;
        this.sharedService.add('Reset code', res.Message);
        this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
      }
    }, (err: HttpErrorResponse) => {
      this.Spinner.hide();
      this.sharedService.add('Login', 'Server error!!!');
      this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
      console.log('Error =>>' + err);
    });
  }

  clearFormData(): void {
    this.UserModel = new Usermodel();
    if (this.forgotForm) {
      this.forgotForm.resetForm(this.UserModel);
    }
  }
  ngOnDestroy(){
    this.seo.removeTag("meta");
    this.seo.removeTag("keywords");
  }
}
