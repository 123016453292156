import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { QuestionModel } from '../../models/questionans/questionmodel';
import { tap, catchError } from 'rxjs/operators';
import { SubjectModel } from '../../models/questionans/subjectmodel';
import { DetailReportModel } from '../../models/evaluationDetail/detailreportmodel';

@Injectable({
  providedIn: 'root'
})
export class QuestionAnsService {

  private quesApiUrl: string = environment.apiUrl + '/QuestionAns';
  constructor(private http: HttpClient) { }

  getQuestion(languageId, RegistrationId): Observable<QuestionModel> {
    const reqHeader = new HttpHeaders({ languageId, RegistrationId });
    return this.http.get<QuestionModel>(this.quesApiUrl, { headers: reqHeader }).pipe(
      tap(() => { }));
  }

  getSubjects(): Observable<SubjectModel[]> {
    const url = this.quesApiUrl + '/subject';
    return this.http.get<SubjectModel[]>(url).pipe(
      tap(() => { }));
  }

  sendReport(RegistrationId): Observable<any> {
    const reqHeader = new HttpHeaders({ RegistrationId });
    const url = this.quesApiUrl + '/report';
    return this.http.get<any>(url, { headers: reqHeader }).pipe(
      tap(() => { }));
  }

  getDetailReport(studentId): Observable<any[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const url = this.quesApiUrl + '/detailreport';
    return this.http.post<any[]>(url, JSON.stringify(studentId), {headers}).pipe(
      tap(() => { }));
  }

  getReportTemplate(id: string): Observable<any[]> {
    const url = this.quesApiUrl + '/detailreport/';
    const htppHeaders = new HttpHeaders().set('RegistrationId', id);
    return this.http.get<any[]>(url,{headers: htppHeaders}).pipe(
      tap(() => { }));
  }

  getReportModel(RegistrationId, isDesktop): Observable<DetailReportModel> {
    const reqHeader = new HttpHeaders({ RegistrationId, isDesktop: isDesktop.toString() });
    const url = this.quesApiUrl + '/getreportmodel';
    return this.http.get<DetailReportModel>(url, { headers: reqHeader }).pipe(
      tap(() => { }));
  }

  getTotalPercentage(RegistrationId): Observable<any> {
    const reqHeader = new HttpHeaders({ RegistrationId });
    const url = this.quesApiUrl + '/totalpercentage';
    return this.http.get<any>(url, { headers: reqHeader }).pipe(
      tap(() => { }));
  }
}
