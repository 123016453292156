import { Component, OnInit, ViewChild } from "@angular/core";
import { Userratingmodel } from "../../model/userratingmodel";
import { NgForm } from "@angular/forms";
import { UserRatingService } from "../../service/user-rating.service";
import { NgxSpinnerService } from "ngx-spinner";
import { DataservicesService } from "src/app/shared/services/dataservice.service";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { AlertmodalComponent } from "src/app/shared/components/alertmodal/alertmodal.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { LinksAndMeta } from "src/app/seo/Model/linksandmeta.model";
import { SEOService } from "src/app/seo/services/seo.service";
import { SEOdetailService } from "src/app/seo/services/seodetail.service";
declare var document: any;
declare var $: any;

@Component({
  selector: "app-add-review",
  templateUrl: "./add-review.component.html",
  styleUrls: ["./add-review.component.css"],
})
export class AddReviewComponent implements OnInit {
  private thisPageSeoDetail: LinksAndMeta;

  ratingModel: Userratingmodel;
  modalRef: BsModalRef;
  errors: any[];
  errorFlag: boolean;

  @ViewChild(NgForm, { static: false }) ratingForm: NgForm;

  constructor(
    private Spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private sharedService: DataservicesService,
    private ratingService: UserRatingService,
    public bsModalRef: BsModalRef,
    private router: Router,
    private seo: SEOService,
    private seoDetail: SEOdetailService
  ) {}

  ngOnInit() {
    this.ratingModel = new Userratingmodel();
    this.errors = [];
    this.errorFlag = false;
    this.seoDetail.getThisRouteSEODetail("addReview").subscribe((response) => {
      if (response) {
        // console.log(response);
        this.thisPageSeoDetail = response;
        this.seo.updateTitle(this.thisPageSeoDetail.PageTitle);
        this.seo.updateTag({
          name: "meta",
          content: this.thisPageSeoDetail.MetaData,
        });
        this.seo.updateTag({
          name: "keywords",
          content: this.thisPageSeoDetail.Keyword,
        });
      } else {
        console.log("addReview SEO Response is null");
      }
    });

    document.querySelectorAll(".star-icon").forEach((item) => {
      item.addEventListener("click", (e) => {
        const limit = item.getAttribute("data-star");

        document.querySelectorAll(".star-icon").forEach((item, index) => {
          item.querySelector(".star-icon-in").classList.add("fa-regular");
          item.querySelector(".star-icon-in").classList.remove("greened");
        });

        document.querySelectorAll(".star-icon").forEach((item, index) => {
          if (index <= limit) {
            item.querySelector(".star-icon-in").classList.add("fa-solid");
            item.querySelector(".star-icon-in").classList.add("greened");
          }
        });
      });
    });
  }

  ngOnDestroy() {
    this.Spinner.hide();
    this.seo.removeTag("meta");
    this.seo.removeTag("keywords");
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  saveRating() {
    if (this.ratingModel.UserRating === undefined) {
      this.errorFlag = true;
      this.errors[0] = "Rating is required";
      return;
    }
    this.errorFlag = false;
    this.errors = [];
    this.Spinner.show();
    this.ratingService.saveUserRating(this.ratingModel).subscribe(
      (res) => {
        this.Spinner.hide();
        if (res.IsSuccessfully) {
          this.errorFlag = false;
          this.bsModalRef.hide();
          this.sharedService.add(
            "Rating submit",
            "Rating successfully submitted."
          );
          this.modalRef = this.modalService.show(AlertmodalComponent, {
            class: "modal-md",
          });
        } else {
          this.errorFlag = true;
          this.errors = res;
        }
      },
      (err: HttpErrorResponse) => {
        this.Spinner.hide();
        this.sharedService.add("Rating Save", "Failed to save rating");
        this.modalRef = this.modalService.show(AlertmodalComponent, {
          class: "modal-md",
        });
        console.log(err);
        this.clearFormData();
      }
    );
  }

  clearFormData(): void {
    this.ratingModel = new Userratingmodel();
    if (this.ratingForm) {
      this.ratingForm.resetForm(this.ratingModel);
    }
    this.errorFlag = false;
    this.errors = [];
  }

  rate1Hover() {
    this.errorFlag = false;
    this.errors = [];
    $(".rater").removeClass("non-rated");
    $(".rater").removeClass("rated");
    $(".rate-1").addClass("rated");
    this.ratingModel.UserRating = 1;
  }

  rate2Hover() {
    this.errorFlag = false;
    this.errors = [];
    $(".rater").removeClass("non-rated");
    $(".rater").removeClass("rated");
    $(".rate-1").addClass("rated");
    $(".rate-2").addClass("rated");
    this.ratingModel.UserRating = 2;
  }
  rate3Hover() {
    this.errorFlag = false;
    this.errors = [];
    $(".rater").removeClass("non-rated");
    $(".rater").removeClass("rated");
    $(".rate-1").addClass("rated");
    $(".rate-2").addClass("rated");
    $(".rate-3").addClass("rated");
    this.ratingModel.UserRating = 3;
  }
  rate4Hover() {
    this.errorFlag = false;
    this.errors = [];
    $(".rater").removeClass("non-rated");
    $(".rater").removeClass("rated");
    $(".rate-1").addClass("rated");
    $(".rate-2").addClass("rated");
    $(".rate-3").addClass("rated");
    $(".rate-4").addClass("rated");
    this.ratingModel.UserRating = 4;
  }
  rate5Hover() {
    this.errorFlag = false;
    this.errors = [];
    $(".rater").removeClass("non-rated");
    $(".rater").addClass("rated");
    this.ratingModel.UserRating = 5;
  }
}
