import {
  Directive,
  Input,
  AfterViewInit,
  Renderer2,
  ElementRef,
  HostListener,
} from "@angular/core";
import { GTMService } from 'src/app/core/services/gtm.service';

@Directive({
  selector: "[gtmEvent]",
})
export class GTMEventDirective implements AfterViewInit {
  constructor(
    private gtmService:GTMService,
    private renderer:Renderer2,private el:ElementRef
   ) {}

  @Input() trackOn: string;
  @Input() eventName: string;
  @Input() eventCategory: string;
  @Input() eventLabel: string;
  @Input() eventValue: number;

  ngAfterViewInit() {
    try {
      this.renderer.listen(this.el.nativeElement, this.trackOn, () => {
        this.gtmService.pushEventTag(
          this.eventName,
          this.eventCategory,
          this.trackOn,
          this.eventLabel,
          this.eventValue
        );
      });
    } catch (err) {
      console.error(err);
    }
  }
}
