export class Userratingmodel {
  public UReviewId: number;
  public UserName: string;
  public UserEmail: string;
  public UserMessage: string;
  public UserRating: number;
  public IsApproved: boolean;
  public UserRelation: string;
  public CreatedBy: string;
  public CreatedOn: string;
  public ApprovedBy: string;
  public ApprovedOn: string;
  public IsArchive: boolean;

  public TotalRecord: number;
}
