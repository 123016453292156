import { Directive, Output, EventEmitter, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';

@Directive({
  selector: '[appSubmitIfValid]'
})
export class SubmitIfValidDirective {

  @Output('appSubmitIfValid') valid = new EventEmitter<void>(); // tslint:disable-line:no-output-rename

  constructor(private formRef: NgForm) {
  }

  @HostListener('click')
  handleClick() {
    this.markFieldsAsDirty();
    this.emitIfValid();
    this.focusLoopFunction()
  }

  focusLoopFunction() {
    for (let index = 1; index <= 9; index++) {
      $("#fm" + index).css("top", "0%");
    }
  }
  private markFieldsAsDirty() {
    Object.keys(this.formRef.controls)
      .forEach(fieldName =>
        this.formRef.controls[fieldName].markAsDirty()
      );
    Object.keys(this.formRef.controls)
      .forEach(fieldName =>
        this.formRef.controls[fieldName].markAsTouched()
      );
  }

  private emitIfValid() {
    if (this.formRef.valid) {
      this.valid.emit();
    }
  }
}
