import { Component, OnInit, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DataservicesService } from '../../services/dataservice.service';
import { Userratingmodel } from 'src/app/modules/home/model/userratingmodel';

@Component({
  selector: 'app-reviewmodal',
  templateUrl: './reviewmodal.component.html',
  styleUrls: ['./reviewmodal.component.css']
})
export class ReviewmodalComponent implements OnInit {

  range:number[];
  marked:number;

  public onClose: Subject<boolean>;
  public reviewModel: Userratingmodel;
  constructor(public bsModalRef: BsModalRef, private sharedService: DataservicesService) { }

  ngOnInit() {
    this.range = [];
    this.marked=-1;
    for (var i = 0; i < 5; i++) {
      this.range.push(i);
    }
    this.onClose = new Subject();
    this.reviewModel=JSON.parse(this.sharedService.msg);
  }

  public isMarked(index){
    if (this.reviewModel.UserRating >= index + 1) {
      return 'rated';
    }
    else{
      return 'non-rated';
    }
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
}
