import { ContactUsComponent } from "./../../../home/components/contact-us/contact-us.component";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { QuestionAnsService } from "../../services/questionans/question-ans.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { DataservicesService } from "src/app/shared/services/dataservice.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AlertmodalComponent } from "src/app/shared/components/alertmodal/alertmodal.component";
import { environment } from "src/environments/environment";
import { GTMService } from "src/app/core/services/gtm.service";
import { LinksAndMeta } from "src/app/seo/Model/linksandmeta.model";
import { SEOService } from "src/app/seo/services/seo.service";
import { SEOdetailService } from "src/app/seo/services/seodetail.service";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.css"]
})
export class ReportComponent implements OnInit {
  private thisPageSeoDetail: LinksAndMeta;

  constructor(
    private quetionAnsService: QuestionAnsService,
    private Spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private sharedService: DataservicesService,
    private activatedRoute: ActivatedRoute,
    private gtmService: GTMService,
    private seo: SEOService,
    private seoDetail: SEOdetailService
  ) {}

  // studentId: any;
  // reportModel: DetailReportModel;
  // isCritical: boolean;
  // isBeneficial: boolean;
  // isUnnecessary: boolean;
  RegistrationId: string;
  // AnswerReportLink: string;
  // barColor: string;
  // respectIndication: string;
  // self: string;
  // secondPerson: string;
  // thirdPerson: string;
  modalRef: BsModalRef;
  // IsLinkEnable: boolean;
  // IsBtnEnable: boolean;

  innerHtml: string = '';
  innerStyle: string = '';

  ngOnInit() {
    // this.isCritical = false;
    // this.isBeneficial = false;
    // this.isUnnecessary = false;
    // this.reportModel = new DetailReportModel();
    this.activatedRoute.queryParams.subscribe((params) => {
      const encryptedId = params.id;
      const isDesktop = /true/i.test(params.isDesktop);
      if (isDesktop) {
        this.RegistrationId = encryptedId;
      } else {
        const re = /\^/gi;
        const replace1 = encryptedId.replace(re, "/");
        const re1 = /\ /gi;
        const replace2 = replace1.replace(re1, "+");
        this.RegistrationId = this.sharedService.DecryptValue(replace2);
      }

      if (this.RegistrationId !== undefined && this.RegistrationId !== null) {
        // this.getReportModel(this.RegistrationId, isDesktop);
        this.quetionAnsService.getReportTemplate(this.RegistrationId).subscribe(data => {
          this.innerHtml = data['Data'];
        })
      }
    });

    this.innerStyle = `
      <style>
        td {
          background-color: unset !important;
        }
        .banner {
          margin-left: 0 !important;
        }
        .try-it-now {
          display: inline !important;
        }
        .window-warning {
          line-height: 4.7 !important;
        }
        .imp {
          color: #fd8b8b !important;
        }
        .em_div {
          display: none !important;
        }
      </style>
    `

    this.seoDetail.getThisRouteSEODetail("report").subscribe((response) => {
      if (response) {
        // console.log(response);
        this.thisPageSeoDetail = response;
        this.seo.updateTitle(this.thisPageSeoDetail.PageTitle);
        this.seo.updateTag({
          name: "meta",
          content: this.thisPageSeoDetail.MetaData,
        });
        this.seo.updateTag({
          name: "keywords",
          content: this.thisPageSeoDetail.Keyword,
        });
      } else {
        console.log("report SEO Response is null");
      }
    });
  }

  // getReportModel(RegistrationId, isDesktop) {
  //   this.Spinner.show();
  //   this.quetionAnsService.getReportModel(RegistrationId, isDesktop).subscribe(
  //     (dr) => {
  //       this.Spinner.hide();
  //       this.reportModel = dr;
  //       // this.AnswerReportLink = '/answers/' + this.reportModel.UserRegEvaModel.StudentId;
  //       this.AnswerReportLink = "/answers";
  //       if (this.reportModel.EmailReportModel.IsNotPerformedExercise) {
  //         this.IsBtnEnable = true;
  //         this.IsLinkEnable = false;
  //       } else {
  //         this.IsBtnEnable = false;
  //         this.IsLinkEnable = true;
  //       }

  //       if (
  //         this.reportModel.UserRegEvaModel.Gender !== null &&
  //         this.reportModel.UserRegEvaModel.Gender.toUpperCase() === "GIRL"
  //       ) {
  //         this.respectIndication = "Miss";
  //         this.self = "she";
  //         this.secondPerson = "her";
  //         this.thirdPerson = "her";
  //       } else {
  //         this.respectIndication = "Master";
  //         this.self = "he";
  //         this.secondPerson = "him";
  //         this.thirdPerson = "his";
  //       }
  //       // this.reportModel.EmailReportModel.TotalPercentage=93
  //       if (
  //         this.reportModel.EmailReportModel.TotalPercentage <
  //         environment.Critical
  //       ) {
  //         this.barColor = "#f2747a";
  //         this.isCritical = true;
  //         this.isBeneficial = false;
  //         this.isUnnecessary = false;
  //       } else if (
  //         this.reportModel.EmailReportModel.TotalPercentage >=
  //           environment.Critical &&
  //         this.reportModel.EmailReportModel.TotalPercentage <
  //           environment.Benefitial
  //       ) {
  //         this.barColor = "#e1b115";
  //         this.isCritical = false;
  //         this.isBeneficial = true;
  //         this.isUnnecessary = false;
  //       } else {
  //         this.barColor = "#99ddb1";
  //         this.isCritical = false;
  //         this.isBeneficial = false;
  //         this.isUnnecessary = true;
  //       }
  //     },
  //     (error) => {
  //       this.Spinner.hide();
  //       this.sharedService.add("Quetions", "Server error.");
  //       this.modalRef = this.modalService.show(AlertmodalComponent, {
  //         class: "modal-sm",
  //       });
  //       console.log(error);
  //     }
  //   );
  // }

  openReport() {
    this.sharedService.add("Report", "There is no perform exercise.");
    this.modalRef = this.modalService.show(AlertmodalComponent, {
      class: "modal-sm",
    });
  }

  openContactUs() {
    this.modalRef = this.modalService.show(ContactUsComponent, {
      backdrop: "static",
      keyboard: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered",
    });
    this.gtmService.pushEventTag(
      "contact-us modal loaded",
      "support",
      "click",
      "User opened support modal"
    );
  }

  ngOnDestroy() {
    this.seo.removeTag("meta");
    this.seo.removeTag("keywords");
  }
}
