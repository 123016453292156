import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SubscribeModel } from '../model/subscribemodel';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ContactUsModel } from '../model/contactusmodel';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private subApiUrl: string = environment.apiUrl + '/Common';
  constructor(private http: HttpClient) { }

  saveSubscription(subscribe: SubscribeModel): Observable<any> {
    return this.http.post<SubscribeModel>(this.subApiUrl, subscribe, environment.httpOptions).pipe(
      tap(() => {}, catchError(this.handleError('getUser', [])))
    );
  }

  sendContactUsMsg(contact: ContactUsModel): Observable<any> {
    const Url = `${this.subApiUrl}/contactusweb`;
    return this.http.post<SubscribeModel>(Url, contact, environment.httpOptions).pipe(
      tap(() => {}, catchError(this.handleError('getUser', [])))
    );
  }

  verifyEmail(userVerificationId: string): Observable<any> {
    const reqHeader = new HttpHeaders({ userVerificationId});
    const Url = `${environment.apiUrl}/UserVerification/VerifyEmail`;
    return this.http.get(Url,  { headers: reqHeader}).pipe(
      tap(() => {}, catchError(this.handleError('getUser', [])))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
