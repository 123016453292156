import { Component, OnInit } from '@angular/core';
import { DataservicesService } from 'src/app/shared/services/dataservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { GTMService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css']
})
export class FinishComponent implements OnInit {

  public isFromDesktop:boolean;
  public TotalPercentage:number;
  public BeneficialLevel:number=85;

  constructor(private sharedService: DataservicesService, private router: Router, public bsModalRef: BsModalRef,private route: ActivatedRoute,
    private gtmService:GTMService) { }

  regId: string;
  GuardianName:string;
  ngOnInit() {
    this.regId = this.sharedService.regId;
    this.TotalPercentage = this.sharedService.percentage;
    this.GuardianName=this.sharedService.username;
    this.isFromDesktop=false;
    
    this.route.queryParamMap.subscribe( map => {
      this.isFromDesktop=map.get('isDesktop')!=null?JSON.parse(map.get('isDesktop')):false;
    });
  }

  onViewReport() {
    this.bsModalRef.hide();
    // this.router.navigate(['/evaluation/report'], { queryParams: { id: this.regId }, skipLocationChange: true });
    // this.regId = '92';
    this.gtmService.pushEventTag('View Report modal unloaded','post evaluation','click','User is redirected to new window ro view report for screening performed');
    if (this.regId !== undefined) {
      const encryptedId = this.sharedService.EncryptValue(this.regId).trim();
      const re = /\//gi;
      const replace1 = encryptedId.replace(re, '^');
      const re1 = /\+/gi;
      const replace2 = replace1.replace(re1, ' ');
      const link = environment.hostingURL + 'evaluation/report?id=' + replace2 + '&isDesktop=false;' ;
      window.open(link, '_blank');
      this.router.navigate(['/home']);
    }
  }

  closeModal(){
    this.gtmService.pushEventTag('View Report modal unloaded','post evaluation','click','User didnot view report and is redirected to home page');
    this.bsModalRef.hide();
    this.router.navigateByUrl('home');
  }

}
