import { Component, enableProdMode, HostListener } from '@angular/core';
declare let gtag: Function;
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MarketingService } from './modules/home/service/marketing.service';
import { GTMService } from './core/services/gtm.service';
import { SEOService } from './seo/services/seo.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Eineihaloshon-Website';
  isShow: boolean;
  topPosToStartShowing = 100;
  modalRef: BsModalRef;

  constructor(public router: Router,
    private route: ActivatedRoute,
    private gtmService: GTMService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private marketingService: MarketingService, private seo: SEOService) {

    // subscribe to router events and send page views to Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.marketingService.IsContainsTrackedUrl(event.url)) {
          this.marketingService.Initialize(event.url);
        }
        //since pageview is configurable from google tag manager
        //this.gtmService.pushPageTag(event);  
      }
    });

    this.seo.addTag({ name: 'meta', content: '' });
    this.seo.addTag({ name: 'keywords', content: '' });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }


  @HostListener('window:scroll')
  checkScroll() {

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  gotoTop() {

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.gtmService.pushEventTag('Scroll up icon displayed', 'Scroll up', 'click', 'Scrolled to top of the page from' + this.router.url);
  }


}
