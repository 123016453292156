import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserRegistrationComponent } from './components/user-registration/user-registration.component';
import { QuestionAnsComponent } from './components/question-ans/question-ans.component';
import { ReportComponent } from './components/report/report.component';


const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Login'
    },
    children: [
      // {
      //   path: 'childregistration',
      //   component: UserRegistrationComponent,
      //   data: {
      //     title: 'ChildRegistration'
      //   }
      // },
      {
        path: 'exercise',
        component: QuestionAnsComponent,
        data: {
          title: 'exercise'
        }
      },
      {
        path: 'report',
        component: ReportComponent,
        data: {
          title: 'About App'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EvaluationRoutingModule { }
