import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SEOdetailService {
  private url:string = environment.apiUrl+'/SEOInfo/GetSEOContent'; 
  private params: HttpParams;

  constructor(private http: HttpClient) { }

  getThisRouteSEODetail(name: string): Observable<any>{
    const Url = `${this.url}?ContentId=${name}`;
    // console.log(Url);
    return this.http.get(Url).pipe(
      tap(() => {}),
      catchError(this.handleError('Getting SEO Details', []))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
