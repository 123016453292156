import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { QuestionModel } from '../../models/questionans/questionmodel';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataservicesService } from 'src/app/shared/services/dataservice.service';
import { QuestionAnsService } from '../../services/questionans/question-ans.service';
import { AlertmodalComponent } from 'src/app/shared/components/alertmodal/alertmodal.component';
import { UserRegistrationModel } from '../../models/userregistration/userregistrationmodel';
import { UserRegistrationComponent } from '../user-registration/user-registration.component';
import { AnswerModel } from '../../models/questionans/answermodel';
import { EvalutionDetailModel } from '../../models/evaluationDetail/evaluationdetailmodel';
import { UserEvaluationDetailService } from '../../services/userevaluationdetail/user-evaluation-detail.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SubjectModel } from '../../models/questionans/subjectmodel';
import { StopExerciseComponent } from '../../modal/stop-exercise/stop-exercise.component';
import { Router } from '@angular/router';
import { SubjectQuetionService } from '../../services/subjectquetion/subject-quetion.service';
import { FinishComponent } from '../../modal/finish/finish.component';

@Component({
  selector: 'app-subject-question',
  templateUrl: './subject-question.component.html',
  styleUrls: ['./subject-question.component.css']
})
export class SubjectQuestionComponent implements OnInit {

  constructor(private Spinner: NgxSpinnerService, private modalService: BsModalService,
              private sharedService: DataservicesService, private questionAnsService: QuestionAnsService,
              private userEvaluationDetailService: UserEvaluationDetailService, private router: Router,
              private subjecQuetionService: SubjectQuetionService) {
  }

  public subjects: SubjectModel[] = [];
  public question: QuestionModel;
  public nextQuestionIndex: number;
  public evalutionDetailModel: EvalutionDetailModel;
  public presentComplete: number;
  public width = 100;
  public timerLock = 0;
  public intrvalLocal: any;
  public timerIntrval: any;
  public timeOutTime: 200;
  public seconds;
  public IsShow;
  public defaultSubject: SubjectModel;
  public subjectRadioVal: any;
  public ansRadioVal: any;
  public totalQuetion: number;
  public isYiddishStudent: boolean;
  public isError: boolean;
  public error: string;
  public bothMulti: string;
  public nextMulti: string;
  public subjectBtnStyle: string[] = [];
  public sendBtnStyle: any;
  public setQuetionAni: any;
  @Input() UserModel: UserRegistrationModel;

  modalRef: BsModalRef;
  @ViewChild('myBar', { static: false }) template: ElementRef;

  ngOnInit() {
    this.init();
   
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    this.Spinner.hide();
    clearInterval(this.intrvalLocal);
    clearInterval(this.timerIntrval);
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges): void {
    if (this.UserModel.Age !== '-') {
      this.init();
      // this.UserModel.RegistrationId = 167;
      this.isYiddishStudent = this.UserModel.LanguageId.toString() === '2';
      if (this.isYiddishStudent) {
        this.defaultSubject.Name = 'איך וויס נישט';
        this.bothMulti = 'ביידע';
        this.nextMulti = 'ווייטער';
      } else {
        this.defaultSubject.Name = 'I don’t know';
        this.bothMulti = 'Both';
        this.nextMulti = 'Next';
      }
      this.getSubjects();
    }
  }

  init(): void {
    this.question = new QuestionModel();
    this.evalutionDetailModel = new EvalutionDetailModel();
    this.IsShow = true;
    this.presentComplete = 0;
    this.seconds = 30;
    this.defaultSubject = new SubjectModel();
    this.defaultSubject.SubjectId = -1;
    this.defaultSubject.IsNotSureSubject = true;
    this.evalutionDetailModel = new EvalutionDetailModel();
    this.evalutionDetailModel.Score = 0;
    this.nextQuestionIndex = 0;
    this.subjectRadioVal = 0;
    this.ansRadioVal = 0;
    this.isError = false;
    this.sendBtnStyle = '';
    for (let i = 0; i < 6; i++) {
      this.subjects[i] = new SubjectModel();
    }
    for (let index = 0; index < 5; index++) {
      this.subjectBtnStyle[index] = '';
    }
  }
 public tempWidth: number;
  getQuestions(LanguageId, RegistrationId): void {
    this.Spinner.show();
    this.IsShow = true;
    this.subjecQuetionService.getQuestion(LanguageId, RegistrationId.toString()).subscribe(qm => {
      this.Spinner.hide();
      this.question = qm;
      this.presentComplete = Math.floor((this.question.NoOfAttemptQuestion / this.question.TotalQuetion) * 100);
      this.nextQuestionIndex = this.question.NoOfAttemptQuestion;
      this.sendBtnStyle = {
        animation: ''
      };
      if (this.question !== null && this.question.NoOfAttemptQuestion < this.question.TotalQuetion) {
        if (this.isYiddishStudent) {
          this.setQuetionAni = {
            animation: '1s animate-Yquestion-in'
          };
        } else {
          this.setQuetionAni = {
            animation: '1s animate-question-in'
          };
        }
        // tslint:disable-next-line: radix
        if (this.question.NoOfAttemptQuestion < parseInt((this.question.TotalQuetion / 2).toString())) {
          this.tempWidth = 100;
        } else {
          this.tempWidth = 27;
          this.question.AnswerEvaModels[0] = new AnswerModel();
        }
        this.nextQuestionIndex = this.question.NoOfAttemptQuestion + 1;
        this.IsShow = false;
        this.move(100, this.timeOutTime);
      } else {
        this.Spinner.hide();
        this.question.AnswerEvaModels[0] = new AnswerModel();
        this.sharedService.add('', '', this.UserModel.RegistrationId);
        this.modalRef = this.modalService.show(FinishComponent, { keyboard: true,
          ignoreBackdropClick: true, class: 'modal-dialog-centered'
        });
        this.sendReport(this.UserModel.RegistrationId);
      }
    }, error => {
        this.Spinner.hide();
        this.sharedService.add('Quetions', 'Server error.');
        this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
        console.log(error);
      });
  }

  getSubjects(): void {
    this.Spinner.show();
    this.IsShow = false;
    this.questionAnsService.getSubjects().subscribe(sm => {
      this.subjects = sm;
      this.getQuestions(this.UserModel.LanguageId, this.UserModel.RegistrationId);
    },
      error => {
        this.Spinner.hide();
        this.sharedService.add('Quetions', 'Server error.');
        this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
        console.log(error);
      });
  }

  saveTimeOutProgress() {
    this.evalutionDetailModel.QuestionId = this.question.QuestionId;
    this.evalutionDetailModel.IsSubjectTimeout = true;
    this.evalutionDetailModel.IsNotSureSubject = false;
    this.evalutionDetailModel.RegistrationId = this.UserModel.RegistrationId;
    this.evalutionDetailModel.RuleId = this.question.RuleId;
    this.evalutionDetailModel.AnswerId = 0;
    this.evalutionDetailModel.SubjectId = -1;
    this.IsShow = true;
    this.sendBtnStyle = {
      animation: '0.5s anime-btn infinite alternate'
    };
    // this.addEvalutionDetali(true);
  }

  validateSubject(item: SubjectModel): void {
    this.evalutionDetailModel.QuestionId = this.question.QuestionId;
    this.evalutionDetailModel.RegistrationId = this.UserModel.RegistrationId;
    this.evalutionDetailModel.IsSubjectTimeout = false;
    this.evalutionDetailModel.IsSubjectAns = item.SubjectId === this.question.SubjectId;
    this.evalutionDetailModel.SubjectId = item.SubjectId;
    this.evalutionDetailModel.IsNotSureSubject = item.IsNotSureSubject;
    this.setSubjectButtonStyle(item.SubjectId);
  }

  setSubjectButtonStyle(SubjectId) {
    if (SubjectId === -1) {
      this.subjectBtnStyle[0] = '';
      this.subjectBtnStyle[1] = '';
      this.subjectBtnStyle[2] = '';
      this.subjectBtnStyle[3] = '';
      this.subjectBtnStyle[4] = 'select-btn-border';
    } else if (SubjectId === 1) {
      this.subjectBtnStyle[0] = 'select-btn-border';
      this.subjectBtnStyle[1] = '';
      this.subjectBtnStyle[2] = '';
      this.subjectBtnStyle[3] = '';
      this.subjectBtnStyle[4] = '';

    } else if (SubjectId === 2) {
      this.subjectBtnStyle[0] = '';
      this.subjectBtnStyle[1] = 'select-btn-border';
      this.subjectBtnStyle[2] = '';
      this.subjectBtnStyle[3] = '';
      this.subjectBtnStyle[4] = '';

    } else if (SubjectId === 3) {
      this.subjectBtnStyle[0] = '';
      this.subjectBtnStyle[1] = '';
      this.subjectBtnStyle[2] = 'select-btn-border';
      this.subjectBtnStyle[3] = '';
      this.subjectBtnStyle[4] = '';

    } else if (SubjectId === 4) {
      this.subjectBtnStyle[0] = '';
      this.subjectBtnStyle[1] = '';
      this.subjectBtnStyle[2] = '';
      this.subjectBtnStyle[3] = 'select-btn-border';
      this.subjectBtnStyle[4] = '';
    } else if (SubjectId === 5) {
      this.subjectBtnStyle[0] = 'select-btn-border';
      this.subjectBtnStyle[1] = '';
      this.subjectBtnStyle[2] = 'select-btn-border';
      this.subjectBtnStyle[3] = '';
      this.subjectBtnStyle[4] = '';

    } else if (SubjectId === 6) {
      this.subjectBtnStyle[0] = '';
      this.subjectBtnStyle[1] = 'select-btn-border';
      this.subjectBtnStyle[2] = '';
      this.subjectBtnStyle[3] = 'select-btn-border';
      this.subjectBtnStyle[4] = '';
    }

  }

  saveEvalutionDetails(): void {
    if (this.evalutionDetailModel.RegistrationId !== undefined && this.evalutionDetailModel.RegistrationId !== 0) {
      this.template.nativeElement.style.width = 100 + '%';
      this.template.nativeElement.style.backgroundColor = '#67b9ff';
      clearInterval(this.intrvalLocal);
      clearInterval(this.timerIntrval);
      if (this.isYiddishStudent) {
        this.setQuetionAni = {
          animation: '1s animate-Yquestion-out'
        };
      } else {
        this.setQuetionAni = {
          animation: '1s animate-question-out'
        };
      }
      this.addEvalutionDetali(false);
    } else {
      this.isError = true;
      this.error = 'Please select one of the answer.';
    }
  }

  addEvalutionDetali(isTimeOut: boolean) {
    this.Spinner.show();
    this.userEvaluationDetailService.addSubjectEvalutionDetali(this.evalutionDetailModel).subscribe(async res => {
      if (res.IsSuccessfully) {
        this.evalutionDetailModel = new EvalutionDetailModel();
        this.evalutionDetailModel.Score = 0;
        this.seconds = 30;
        this.timerLock = 0;
        this.subjectRadioVal = 0;
        this.ansRadioVal = 0;
        this.error = '';
        this.isError = false;
        for (let index = 0; index < 5; index++) {
          this.subjectBtnStyle[index] = '';
        }
        this.getQuestions(this.UserModel.LanguageId, this.UserModel.RegistrationId);
      }
    }, (err: HttpErrorResponse) => {
      this.Spinner.hide();
      console.log(err);
    });
  }

  move(width, timeOutTime): void {
    this.timerIntrval = setInterval(() => {
      this.seconds--;
      if (this.seconds <= 0) { clearInterval(this.timerIntrval); }
    }, 1000);
    if (this.timerLock === 0) {
      this.timerLock = 1;
      this.width = width;
      this.intrvalLocal = setInterval(() => { this.frame(); }, 300);
    }
  }

  frame() {
    if (this.width === 50) {
      // this.template.nativeElement.style.backgroundColor = 'orange';
    }
    if (this.width === 35) {
      // this.template.nativeElement.style.backgroundColor = '#ff9567';
    }
    if (this.width === 15) {
      // this.template.nativeElement.style.backgroundColor = '#ff6796';
    }
    if (this.width <= 0) {
      clearInterval(this.intrvalLocal);
      this.saveTimeOutProgress();
      this.timerLock = 0;
    } else {
      this.width--;
      this.template.nativeElement.style.width = this.width + '%';
      this.template.nativeElement.style.backgroundPositionX = '40%';
    }
  }

  stopExercise() {
    this.modalRef = this.modalService.show(StopExerciseComponent, { ignoreBackdropClick: true, class: 'modal-dialog-centered' });
    this.modalRef.content.onClose.subscribe(result => {
      if (result !== null && result === true) {
        clearInterval(this.intrvalLocal);
        clearInterval(this.timerIntrval);
        this.sendReport(this.UserModel.RegistrationId);
      }
    });
  }

  sendReport(RegistrationId) {
    this.Spinner.show();
    this.IsShow = false;
    this.questionAnsService.sendReport(RegistrationId.toString()).subscribe(sm => {
      this.Spinner.hide();
      if (sm !== null && sm !== undefined) {
        // this.sharedService.add('Report', sm.Message);
        // this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
        // if (sm.IsSuccessfully === true) {
        //   this.router.navigate(['/']);
        // }
      }
    },
      error => {
        this.Spinner.hide();
        this.sharedService.add('Report', 'Server error.');
        this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
        console.log(error);
      });
  }
}
