import { Directive, AfterContentInit, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterContentInit {

  //@Input() public appAutoFocus: boolean;
  public constructor(private hostElement: ElementRef) {}

  public ngAfterContentInit() {

      setTimeout(() => {

          this.hostElement.nativeElement.focus();
      }, 500);

  }

}
