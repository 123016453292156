import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IpService {

  //use this when using https call
  private SecureIpApiUrl: string = 'https://api64.ipify.org?format=json';
  //use this when in localhost and the call is http
  private NonSecureIpApiUrl: string = 'http://api64.ipify.org/?format=json';
  private SecureIpGeoLocationAPiUrl:string ='https://api.ipgeolocationapi.com/geolocate/';
  private NonSecureIpGeoLocationAPiUrl:string ='https://api.ipgeolocationapi.com/geolocate/';
  constructor(private http: HttpClient) { }


  getIpAddress(): Observable<any> {
    const ipUrl=environment.production?this.SecureIpApiUrl:this.NonSecureIpApiUrl;
    return this.http.get(ipUrl).pipe(
      tap(() => {}, catchError(this.handleError('getIp', [])))
    );
  }

  getIpGelocation(ipAddress:string): Observable<any> {
    const IpGeoLocationAPiUrl=environment.production?this.SecureIpGeoLocationAPiUrl:this.NonSecureIpGeoLocationAPiUrl;
    const url=`${IpGeoLocationAPiUrl}/${ipAddress}`;
    return this.http.get(url).pipe(
      tap(() => {}, catchError(this.handleError('getIpGelocation', [])))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
