import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { GTMService } from "src/app/core/services/gtm.service";
import { Download2Component } from "../download2/download2.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private gtmService: GTMService,
    private router: Router
  ) {}

  modalRef: BsModalRef;
  ngOnInit() {}

  closeModal() {
    this.bsModalRef.hide();
    this.router.navigate([]);
    this.gtmService.pushEventTag(
      "Download modal unloaded",
      "Download",
      "click",
      "User close Download dialog"
    );
  }

  openDownload2App() {
    this.closeModal();
    this.modalRef = this.modalService.show(Download2Component, {
      backdrop: "static",
      keyboard: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered max-598",
    });
    this.gtmService.pushEventTag(
      "Download2 modal loaded",
      "Download2",
      "click",
      "User opened Download2 modal"
    );
  }
}
