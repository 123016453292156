import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordvalidationDirective } from '../../directives/passwordvalidation.directive';
import { SubmitIfValidDirective } from '../../directives/formsubmitval/submit-if-valid.directive';
import { HeaderComponent } from 'src/app/containers/default-layout/header/header.component';
import { RouterModule } from '@angular/router';
import { TrimStr } from '../../pipes/trim.pipe';
import { AutofocusDirective } from '../../directives/autofocus/autofocus.directive';
import { GTMEventDirective } from '../../directives/gtag/gtm-event.directive';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    SubmitIfValidDirective,
    HeaderComponent,
    PasswordvalidationDirective, AutofocusDirective, GTMEventDirective, SafeHtmlPipe
  ],
  exports: [
    PasswordvalidationDirective,
    SubmitIfValidDirective,
    HeaderComponent,
    AutofocusDirective,
    GTMEventDirective,
    SafeHtmlPipe
  ]
})
export class SharedModule { }
