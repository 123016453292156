import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Marketingdetailmodel } from '../model/marketingdetailmodel';
import { tap, catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { IpService } from 'src/app/core/services/ip.service';
import { Location } from '@angular/common';
import { GTMService } from 'src/app/core/services/gtm.service';

@Injectable({
  providedIn: 'root'
})
export class MarketingService {

  private marketingApiUrl: string = environment.apiUrl + '/MarketingCampaign';
  private marketingDetailModel:Marketingdetailmodel;

  //sprecify the url that needs to be tracked
  private campaignMap='?xcd=';

  constructor(private http: HttpClient, private route:ActivatedRoute,private Spinner:NgxSpinnerService,private gtmService:GTMService,private ipService:IpService, private location:Location) { }

  IsContainsTrackedUrl(trackedUrl:string):boolean
  {
    if(trackedUrl.indexOf(this.campaignMap) != -1)
    {
      return true;
    }
    return false;
  }

  Initialize(trackedUrl:string)
  {
    this.marketingDetailModel=new Marketingdetailmodel();    
    this.route.queryParamMap.subscribe( map => {
      const campaingId = map.get('xcd');
      const guardianEmail=map.get('xge');
      if(campaingId!=null)
      {
        if(guardianEmail!=null)
        {
          this.marketingDetailModel.GuardianEmail=guardianEmail;
        }
        this.marketingDetailModel.CampaignId=campaingId;
        this.location.replaceState(trackedUrl.split(this.campaignMap,2)[0]);
        this.getIpAdress();
      }
    });
    
  }

  getIpAdress() {
    this.Spinner.show();
    this.ipService.getIpAddress().subscribe(response=>{
      if(response!=null)
      {
        this.marketingDetailModel.UserIPAddress=response.ip;
      }
      this.Spinner.hide();
      this.saveMarketingCampaignDetails();
    },
      (error: HttpErrorResponse) => {
        this.Spinner.hide();
        console.log(error.error);
      }
      )
  }

  saveMarketingCampaignDetails()
  {
    this.Spinner.show();
    this.gtmService.pushEventTag('email campaign saved','email campaign','link clicked','User opened site with link  containing email campaign Id');
    this.upsertMarketingDetail(this.marketingDetailModel).subscribe(response=>{
      this.Spinner.hide();
    },
      (error: HttpErrorResponse) => {
        this.Spinner.hide();
        console.log(error.error);
      }
    );
  }

  upsertMarketingDetail(marketingDetail:Marketingdetailmodel): Observable<any> {
    const url=`${this.marketingApiUrl}/SaveMarketingCampaignDetails`;
    return this.http.post<Marketingdetailmodel>(url, marketingDetail, environment.httpOptions).pipe(
      tap(() => {}, catchError(this.handleError('upsertMarketingCampaignDetails', [])))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
