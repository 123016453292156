import { RouterModule } from '@angular/router';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './modules/login/login.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AlertmodalComponent } from './shared/components/alertmodal/alertmodal.component';

import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginDefaultLayoutComponent } from './containers/login-default-layout/login-default-layout.component';
import { UserRegistrationComponent } from './modules/evaluation/components/user-registration/user-registration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EvaluationDefaultLayoutComponent } from './containers/evaluation-default-layout/evaluation-default-layout.component';
import { EvaluationModule } from './modules/evaluation/evaluation.module';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
import { FooterComponent } from './containers/default-layout/footer/footer.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DefaultAdminLayoutComponent } from './containers/default-admin-layout/default-admin-layout.component';
import { ContactUsComponent } from './modules/home/components/contact-us/contact-us.component';
import { SharedModule } from './shared/modules/shared/shared.module';
import { AddReviewComponent } from './modules/home/components/add-review/add-review.component';
import { ReviewmodalComponent } from './shared/components/reviewmodal/reviewmodal.component';
import { UserVerificationComponent } from './modules/home/components/user-verification/user-verification.component';
import { environment } from 'src/environments/environment';
import { GTMService } from './core/services/gtm.service';
import { SEOService } from './seo/services/seo.service';
import { SEOdetailService } from './seo/services/seodetail.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';


@NgModule({
  declarations: [
    AppComponent,
    AlertmodalComponent,
    LoginDefaultLayoutComponent,
    UserRegistrationComponent,
    EvaluationDefaultLayoutComponent,
    DefaultLayoutComponent,
    FooterComponent,
    DefaultAdminLayoutComponent,
    ContactUsComponent,AddReviewComponent, ReviewmodalComponent,UserVerificationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    EvaluationModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    FormsModule, ReactiveFormsModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SharedModule,RouterModule
  ],
  providers: [BsModalService,
    {provide: 'googleTagManagerId', useValue: environment.CurrentGTMId},
    SEOService,Meta,SEOdetailService
  ],
  entryComponents: [
    AlertmodalComponent,
    UserRegistrationComponent,
    ContactUsComponent,AddReviewComponent, ReviewmodalComponent
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private gtmService:GTMService) {
    //load gtm scripts in head and noscripts in body tag
    gtmService.addGtmToDom();
}
}
