import { Injectable, Inject } from "@angular/core";
import { environment } from "src/environments/environment";
import { NavigationEnd } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { GoogleTagManagerService } from "angular-google-tag-manager";

declare let gtag: Function;
declare var window: any;

@Injectable({
  providedIn: "root",
})
export class GTMService {
  
  constructor(
    @Inject(DOCUMENT) document: any,
    private gtmService: GoogleTagManagerService
  ) {}

  //#region Code related to Google Analytics
  DevGtagId : "UA-173454224-1";
  ProdGtagId : "UA-168843713-1";
  private CurrentGtagId = this.DevGtagId;
  
  loadGoogleAnalytics() {
    // injecting GA main script asynchronously
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${this.CurrentGtagId}`;
    script.async = true;
    document.head.appendChild(script);

    // preparing GA API to be usable even before the async script is loaded
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    gtag("js", new Date());
    gtag("config", this.CurrentGtagId);
  }

  public sendEvents(
    eventName: string, //Name of the event, ex-contact-us pop show
    eventCategory: string, //Name of the event category, ex-support
    eventAction: string, //Name of the event_action, ex-click
    eventLabel: string = null, //Name of the event_label or description, ex-support pop up open
    eventValue: number = null //Name of the event_value, ex-10 or 20
  ) {
    gtag("event", eventName, {
      event_category: eventCategory,
      event_label: eventLabel,
      event_action: eventAction,
      value: eventValue,
    });
  }

  public sendPageViews(event: NavigationEnd) {
    gtag("config", this.CurrentGtagId, {
      page_title: event.urlAfterRedirects,
      page_path: event.urlAfterRedirects,
    });
  }

  //#endregion

  public addGtmToDom() {
    this.gtmService.addGtmToDom();
  }

  public pushPageTag(event: NavigationEnd) {
    const gtmTag = {
      event: "page",
      pageName: event.url,
    };

    this.gtmService.pushTag(gtmTag);
    //console.log(window["dataLayer"]);
  }

  public pushEventTag(
    eventName: string, //Name of the event, ex-contact-us pop show
    eventCategory: string, //Name of the event category, ex-support
    eventAction: string, //Name of the event_action, ex-click
    eventLabel: string = null, //Name of the event_label or description, ex-support pop up open
    eventValue: number = null //Name of the event_value, ex-10 or 20
  ) {
    const gtmTag = {
      event: eventName,
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue,
    };

    this.gtmService.pushTag(gtmTag);
    //console.log(window["dataLayer"]);
  }
}
