import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map, tap, debounce } from 'rxjs/operators';
import { Usermodel } from '../../models/usermodel';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userApiUrl: string = environment.apiUrl + '/user';
  constructor(private http: HttpClient) { }

  addUser(user: Usermodel): Observable<any> {
    return this.http.post<Usermodel>(this.userApiUrl, user, environment.httpOptions).pipe(
      tap(() => {}, catchError(this.handleError('getUser', [])))
    );
  }

  getUsers(): Observable<Usermodel[]> {
    return this.http.get<Usermodel[]>(this.userApiUrl).pipe(
      tap(users => {}, catchError(this.handleError('getUser', [])))
    );
  }

  updateUserPass(user: Usermodel): Observable<any> {
    return this.http.put<Usermodel>(this.userApiUrl, user, environment.httpOptions).pipe(
      tap(() => {}, catchError(this.handleError('getUser', [])))
    );
  }

  getUserById(UserId: string): Observable<Usermodel> {
    const url = `${this.userApiUrl}/${UserId}`;
    return this.http.get<Usermodel>(url).pipe(
      tap(() => {}, catchError(this.handleError('getUser', [])))
    );
  }

  getUserByEmail(Email: string): Observable<Usermodel> {
    const url = `${this.userApiUrl}/${Email}/email`;
    return this.http.get<Usermodel>(url).pipe(
      tap(() => {}, catchError(this.handleError('getUser', [])))
    );
  }

  // login(userID: string, Password: string): Observable<any> {
  //   const url = `${this.userApiUrl}/login`;
  //   const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', userId : userID, password : Password, isSendMail: 'true' });
  //   return this.http.get<any>(url, { headers: reqHeader }).pipe(
  //     tap(users => {}, catchError(this.handleError('getUser', [])))
  //   );
  // }
  login(Email: string): Observable<any> {
    const url = `${this.userApiUrl}/login`;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', userId : Email, password : '', isSendMail: 'true' });
    return this.http.get<any>(url, { headers: reqHeader }).pipe(
      tap(users => {}, catchError(this.handleError('getUser', [])))
    );
  }
  sendCode(userName: string): Observable<any> {
    const url = `${this.userApiUrl}/${userName}/code`;
    return this.http.get<any>(url).pipe(
      tap(() => {}, catchError(this.handleError('getUser', [])))
    );
  }

  verifyPassCode(userID: string, resetCode: string): Observable<any> {
    const url = `${this.userApiUrl}/verifyPassCode`;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', userId : userID, ResetCode : resetCode });
    return this.http.get<any>(url, { headers: reqHeader }).pipe(
      tap(users => {}, catchError(this.handleError('getUser', [])))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
