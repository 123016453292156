
export class Usermodel {
  constructor() { }
  public UserId: string;
  public FirstName: string;
  public LastName: string;
  public Email: string;
  public UserPassword: string;
  public ConfirmPassword: string;
  public ResetCode: string;
  public ServersideForUseId = false;
  public ServersideForEmail = false;
}
