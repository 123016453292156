import { Injectable } from '@angular/core';
import { Title, Meta ,MetaDefinition } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private title: Title, private meta: Meta) { }
  updateTitle(title: string) {
    this.title.setTitle(title);
  }


  addTag(tag:MetaDefinition){
    this.meta.addTag(tag,true);
  }

  updateTag(tag: MetaDefinition){
    this.meta.updateTag(tag);
  }

  removeTag(tag: string){
    this.meta.removeTag('name = '+tag);
  }
}
