import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { CountryCodes, UserRegistrationModel } from "../../models/userregistration/userregistrationmodel";
import { LanguageModel } from "../../models/language/languagemodel";

@Injectable({
  providedIn: "root",
})
export class UserRegistrationService {
  private userApiUrl: string = environment.apiUrl + "/UserRegEva";
  constructor(private http: HttpClient) {}

  addUser(user: UserRegistrationModel): Observable<any> {
    return this.http
      .post<UserRegistrationModel>(
        this.userApiUrl,
        user,
        environment.httpOptions
      )
      .pipe(tap(() => {}, catchError(this.handleError("getUser", []))));
  }

  getLanguages(): Observable<LanguageModel[]> {
    const url = this.userApiUrl + "/language";
    return this.http
      .get<LanguageModel[]>(url)
      .pipe(tap(() => {}, catchError(this.handleError("getActiveRole", []))));
  }

  registerEmail(email: string): Observable<any> {
    const url = `${environment.apiUrl}/ScreeningEmailVerify/SendVerificationEmail`;
    const headers = new HttpHeaders().append("TeacherEmail_Id", email);
    return this.http
      .get<any>(url, { headers })
      .pipe(tap(() => {}, catchError(this.handleError("RegisterEmail", []))));
  }

  verifyOTP(email: string, otp: string): Observable<any> {
    const url = `${environment.apiUrl}/ScreeningEmailVerify/VerifyScreeningEmail`;
    const headers = new HttpHeaders()
      .append("TeacherEmail_Id", email)
      .append("Code", otp);
    return this.http
      .get<any>(url, { headers })
      .pipe(tap(() => {}, catchError(this.handleError("VerifyOTP", []))));
  }

  getCountryCodeList(): Observable<CountryCodes[]> {
    const url = `${environment.apiUrl}/Country/GetCountryList`;
    return this.http.get<CountryCodes[]>(url).pipe(tap(() => {}, catchError(this.handleError("VerifyOTP", []))));
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
