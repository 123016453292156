import { NavigationEnd, Router } from "@angular/router";
import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { UserRegistrationModel } from "src/app/modules/evaluation/models/userregistration/userregistrationmodel";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ContactUsComponent } from "src/app/modules/home/components/contact-us/contact-us.component";
import { GTMService } from "src/app/core/services/gtm.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements AfterViewInit{
  // Media Queries
  isSmall: boolean = false;

  modalRef: BsModalRef;

  @Input() UserModel: UserRegistrationModel;
  showSutdentInfo = false;
  constructor(
    private modalService: BsModalService,
    private gtmService: GTMService,
    private router: Router,
  ) {

  }

  ngAfterViewInit(): void {
    let v = this.router.url.split("/");
    if(v[1].startsWith('report')){
      $("header").addClass('fixed_header')
    }else{
      $("header").removeClass('fixed_header')
    }
    this.isSmall = window.matchMedia(
      '(min-width: 1px) and (max-width: 1199px)').matches;
  }

  ngOnChanges() {
    if (
      this.UserModel !== null &&
      this.UserModel.ChildFName !== undefined &&
      this.UserModel.ChildFName !== null &&
      this.UserModel.ChildFName !== ""
    ) {
      this.showSutdentInfo = true;
    } else {
      this.showSutdentInfo = false;
    }
  }
  openContactUs() {
    this.modalRef = this.modalService.show(ContactUsComponent, {
      backdrop: "static",
      keyboard: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered max-598",
    });
    this.gtmService.pushEventTag(
      "contact-us modal loaded",
      "support",
      "click",
      "User opened support modal"
    );
  }

  mobileMenu() {
    $(".mobile-menu-wrapper").addClass("show");
    $(".overlay2").addClass("show");
  }

  CloseSide() {
    $(".mobile-menu-wrapper.show").removeClass("show");
    $(".overlay2.show").removeClass("show");
  }
}
