import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginDefaultLayoutComponent } from './containers/login-default-layout/login-default-layout.component';
import { EvaluationDefaultLayoutComponent } from './containers/evaluation-default-layout/evaluation-default-layout.component';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
import { DefaultAdminLayoutComponent } from './containers/default-admin-layout/default-admin-layout.component';
import { LandingHomeComponent } from './modules/landing-home/landing-home.component';
import { UserVerificationComponent } from './modules/home/components/user-verification/user-verification.component';
export const routes: Routes = [
 
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  // {
  //   path: '',
  //   component: LoginDefaultLayoutComponent,
  //   data: {
  //     title: 'Login'
  //   },
  //   children: [
  //     {
  //       path: 'download',
  //       loadChildren: './modules/login/login.module#LoginModule'
  //     },
  //   ]
  // },
  {
    path: '',
    component: EvaluationDefaultLayoutComponent,
    data: {
      title: 'Evaluation'
    },
    children: [
      {
        path: 'evaluation',
        loadChildren: './modules/evaluation/evaluation.module#EvaluationModule'
      }
    ]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
        loadChildren: './modules/home/home.module#HomeModule'
      }
    ]
  },
  {
    path: 'userverified',
    component: UserVerificationComponent,
    data: {
      title: 'Answer App'
    }
  },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
