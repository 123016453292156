import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataservicesService } from 'src/app/shared/services/dataservice.service';
import { CommonService } from '../../service/common.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertmodalComponent } from 'src/app/shared/components/alertmodal/alertmodal.component';
import { SubscribeModel } from '../../model/subscribemodel';
import { environment } from 'src/environments/environment';
import { LinksAndMeta } from 'src/app/seo/Model/linksandmeta.model';
import { SEOService } from 'src/app/seo/services/seo.service';
import { SEOdetailService } from 'src/app/seo/services/seodetail.service';

@Component({
  selector: 'app-user-verification',
  templateUrl: './user-verification.component.html',
  styleUrls: ['./user-verification.component.css']
})
export class UserVerificationComponent implements OnInit {
  private thisPageSeoDetail: LinksAndMeta;

  constructor(private activatedRoute: ActivatedRoute,
              private Spinner: NgxSpinnerService, private modalService: BsModalService,
              private sharedService: DataservicesService, private router: Router,
              private common: CommonService, private commonService: CommonService,private seo: SEOService,
              private seoDetail: SEOdetailService) { }

  userVerificationId: any;
  modalRef: BsModalRef;
  subscribeModel: SubscribeModel;

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.userVerificationId = params.id;
      if (this.userVerificationId !== null && this.userVerificationId !== undefined && this.userVerificationId !== '') {
        this.Spinner.show();
        this.common.verifyEmail(this.userVerificationId).subscribe(dr => {
          this.Spinner.hide();
        },
          (error: HttpErrorResponse) => {
            this.Spinner.hide();
            this.sharedService.add('Report', 'Server error.');
            this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
            console.log(error.error);
          });
      } else {
       this.router.navigate(['/home']);
      }
    });

    this.seoDetail.getThisRouteSEODetail('userVerification')
    .subscribe(response => {
      if(response)
      {
        // console.log(response);
        this.thisPageSeoDetail = response;
        this.seo.updateTitle(this.thisPageSeoDetail.PageTitle);
        this.seo.updateTag({name: 'meta', content: this.thisPageSeoDetail.MetaData});
        this.seo.updateTag({name: 'keywords', content: this.thisPageSeoDetail.Keyword});
      }
      else{
        console.log("userVerification SEO Response is null");
      }
    });;
  }

  saveSubscription() {
    this.Spinner.show();
    this.commonService.saveSubscription(this.subscribeModel).subscribe(res => {
      this.Spinner.hide();
      this.clearFormData();
      if (res.IsSuccessfully) {
        this.sharedService.add('Subscription', 'You have subscribed successfully.');
        this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
      }
    }, (err: HttpErrorResponse) => {
      this.Spinner.hide();
      this.sharedService.add('Subscriptione', 'Failed to subscribed');
      this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
      console.log(err);
    });  
  }
  mainHome() {
    const link = environment.hostingURL;
    window.open(link, '_blank');
  }
  clearFormData(): void {
    this.subscribeModel = new SubscribeModel();
  }

  ngOnDestroy(){
    this.seo.removeTag("meta");
    this.seo.removeTag("keywords");
  }
}
