import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserRegistrationModel } from '../../models/userregistration/userregistrationmodel';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EvalutionDetailModel } from '../../models/evaluationDetail/evaluationdetailmodel';

@Injectable({
  providedIn: 'root'
})
export class UserEvaluationDetailService {

  private detailApiUrl: string = environment.apiUrl + '/UserEvaluationDetail';
  constructor(private http: HttpClient) { }

  addEvalutionDetali(evalutionDetail: EvalutionDetailModel): Observable<any> {
    return this.http.post<EvalutionDetailModel>(this.detailApiUrl, evalutionDetail, environment.httpOptions).pipe(
      tap(() => {}, catchError(this.handleError('getUser', [])))
    );
  }

  addSubjectEvalutionDetali(evalutionDetail: EvalutionDetailModel): Observable<any> {
    return this.http.put<EvalutionDetailModel>(this.detailApiUrl, evalutionDetail, environment.httpOptions).pipe(
      tap(() => {}, catchError(this.handleError('getUser', [])))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
