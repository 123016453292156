import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'trimStr'})
export class TrimStr implements PipeTransform {
  transform(value: string): string {
    let newStr = '';
    newStr = value.trim();
    return newStr;
  }
}
