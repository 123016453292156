import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GTMService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-stop-exercise',
  templateUrl: './stop-exercise.component.html',
  styleUrls: ['./stop-exercise.component.css']
})
export class StopExerciseComponent implements OnInit {
  public onClose: Subject<boolean>;
  constructor(public bsModalRef: BsModalRef,private gtmService:GTMService) { }

  ngOnInit() {
    this.onClose = new Subject();
  }
  public onConfirm(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
    this.gtmService.pushEventTag('Stop exercise modal unloaded','post evaluation','click','User stop screening and generate report');
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
    this.gtmService.pushEventTag('Stop exercise modal unloaded','post evaluation','click','User Continue to screening');
  }
}
