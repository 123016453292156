export class EvalutionDetailModel {
  constructor() {}
  public UserEvaluationId: number;
  public RegistrationId: number;
  public SubjectId: number;
  public QuestionId: number;
  public AnswerId: number;
  public Score: number;
  public RuleId: number;
  public IsTimeout: boolean;
  public IsNotSure: boolean;
  public IsNotSureSubject: boolean;
  public IsSubjectAns: boolean;
  public IsSubjectTimeout: boolean;
  public OptionalAns1: number;
  public OptionalAns2: number;
  public OptionalAns3: number;
}
