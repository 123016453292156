import { Component, OnInit } from "@angular/core";
import { SubscribeModel } from "src/app/modules/home/model/subscribemodel";
import { NgxSpinnerService } from "ngx-spinner";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { DataservicesService } from "src/app/shared/services/dataservice.service";
import { CommonService } from "src/app/modules/home/service/common.service";
import { AlertmodalComponent } from "src/app/shared/components/alertmodal/alertmodal.component";
import { HttpErrorResponse } from "@angular/common/http";
import { GTMService } from "src/app/core/services/gtm.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private Spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private sharedService: DataservicesService,
    private gtmService: GTMService,
    private router: Router
  ) {}

  subscribeModel: SubscribeModel;
  modalRef: BsModalRef;

  ngOnInit() {
    this.subscribeModel = new SubscribeModel();
  }

  saveSubscription() {
    this.Spinner.show();
    this.commonService.saveSubscription(this.subscribeModel).subscribe(
      (res) => {
        this.Spinner.hide();
        this.clearFormData();
        if (res.IsSuccessfully) {
          this.sharedService.add(
            "Subscription",
            "You have subscribed successfully."
          );
          this.modalRef = this.modalService.show(AlertmodalComponent, {
            class: "modal-sm",
          });
          this.gtmService.pushEventTag(
            "Save subscription",
            "Subscription",
            "click",
            "User subscribing to get updates"
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.Spinner.hide();
        this.sharedService.add("Subscriptione", "Failed to subscribed");
        this.modalRef = this.modalService.show(AlertmodalComponent, {
          class: "modal-sm",
        });
        console.log(err);
      }
    );
  }
  mainHome() {
    const link = environment.hostingURL;
    window.open(link, "_blank");
  }
  clearFormData(): void {
    this.subscribeModel = new SubscribeModel();
  }

  onClickDownload() {
    this.router.navigate([this.router.url], {
      queryParams: { download: true },
    });
  }

  CloseSide() {
    $(".mobile-menu-wrapper.show").removeClass("show");
    $(".overlay2.show").removeClass("show");
  }
}
