export class AnswerModel {
    constructor() { }
    public AnswerId: number;
    public Answer: string;
    public QuestionId: number;
    public IsTrueAns: boolean;
    public IsNotSure: boolean;
    public LanguageId: number;
    public IsAnsweredByUser: boolean;
  }
