import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SubscribeModel } from '../model/subscribemodel';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Userratingmodel } from '../model/userratingmodel';
import { RatingSearchModel, OrderDirection } from '../model/ratingsearchmodel';

@Injectable({
  providedIn: 'root'
})
export class UserRatingService {

  private userRatingApiUrl: string = environment.apiUrl + '/UserRating';
  constructor(private http: HttpClient) { }

  saveUserRating(userRating:Userratingmodel): Observable<any>{
    return this.http.post<SubscribeModel>(this.userRatingApiUrl+'/SaveUserRatingWeb', userRating, environment.httpOptions).pipe(
      tap(() => {}, catchError(this.handleError('saveRating', [])))
    );
  }

  getAllUserRating(searchModel:RatingSearchModel): Observable<any>{
    let orderDirection=searchModel.OrderDirection==OrderDirection.Ascending?'asc':'desc';
    const Url = `${this.userRatingApiUrl}/GetAllUserRating?isw=0&_sort=${searchModel.Sort}&_order=${orderDirection}&_page=${searchModel.PageNumber}&_limit=${searchModel.PageLimit}&searchPara=${searchModel.SearchParameter}&IsApproved_like=${searchModel.IsApproved}&UserRating_like=${searchModel.UserRating}&UserRelation_like=${searchModel.UserRelation}&UserEmail_like=${searchModel.UserEmail}`;
    return this.http.get(Url).pipe(
      tap(() => {}, catchError(this.handleError('getRatingList', [])))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
