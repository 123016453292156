import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './components/login/login.component';
import { Download2Component } from './components/download2/download2.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordvalidationDirective } from 'src/app/shared/directives/passwordvalidation.directive';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from 'src/app/shared/modules/shared/shared.module';
import { ForgotPasswordStep2Component } from './components/forgot-password-step2/forgot-password-step2.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { AfterdownloadComponent } from './components/afterdownload/afterdownload.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { DownloadComponent } from './components/download/download.component';


@NgModule({
  declarations: [LoginComponent, Download2Component, NewPasswordComponent,
    ForgotPasswordComponent,
    ForgotPasswordStep2Component,
    AfterdownloadComponent, DownloadComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    FormsModule, ReactiveFormsModule,
    NgxSpinnerModule,
    SharedModule, ProgressbarModule.forRoot(), ModalModule.forRoot()
  ],
  exports: [
  ],
  providers: [
    BsModalService,
    BsModalRef
  ],
  entryComponents:[
    DownloadComponent
  ]

})
export class LoginModule { }
