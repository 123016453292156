import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataservicesService } from 'src/app/shared/services/dataservice.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { Usermodel } from '../../models/usermodel';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertmodalComponent } from 'src/app/shared/components/alertmodal/alertmodal.component';

@Component({
  selector: 'app-forgot-password-step2',
  templateUrl: './forgot-password-step2.component.html',
  styleUrls: ['./forgot-password-step2.component.css']
})
export class ForgotPasswordStep2Component implements OnInit {

  UserModel: Usermodel;
  modalRef: BsModalRef;
  private userId;

  @ViewChild(NgForm, {static: false}) forgotForm: NgForm;
  @ViewChild('verify', { static: true }) verify: ElementRef;

  constructor(private userService: UserService, private Spinner: NgxSpinnerService,
              private modalService: BsModalService, private sharedService: DataservicesService,
              private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.UserModel = new Usermodel();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.userId = params.id;
      if (this.userId === null && this.userId === undefined) {
        this.router.navigate(['/download/forgotpassword']);
      }
      this.UserModel.UserId = this.userId;
      this.verify.nativeElement.focus();
    });
  }

  verifyPassCode() {
    this.Spinner.show();
    this.userService.verifyPassCode(this.UserModel.UserId, this.UserModel.ResetCode).subscribe(res => {
      this.Spinner.hide();
      if (res.IsSuccessfully) {
        this.router.navigate(['/download/newpassword'], {
          queryParams: { id: this.UserModel.UserId },
          skipLocationChange: true
        });
        this.clearFormData();
      } else {
        this.UserModel.UserPassword = null;
        this.sharedService.add('Reset code', res.Message);
        this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
      }
    }, (err: HttpErrorResponse) => {
      this.Spinner.hide();
      this.sharedService.add('Login', 'Server error!!!');
      this.modalRef = this.modalService.show(AlertmodalComponent, { class: 'modal-sm' });
      console.log('Error =>>' + err);
    });
  }

  clearFormData(): void {
    this.UserModel = new Usermodel();
    if (this.forgotForm) {
      this.forgotForm.resetForm(this.UserModel);
    }
  }

}
