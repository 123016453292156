import { Component, OnInit, ViewChild } from "@angular/core";
import { ContactUsModel } from "../../model/contactusmodel";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgForm } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { DataservicesService } from "src/app/shared/services/dataservice.service";
import { CommonService } from "../../service/common.service";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { AlertmodalComponent } from "src/app/shared/components/alertmodal/alertmodal.component";
import { GTMService } from "src/app/core/services/gtm.service";
import { LinksAndMeta } from "src/app/seo/Model/linksandmeta.model";
import { SEOService } from "src/app/seo/services/seo.service";
import { SEOdetailService } from "src/app/seo/services/seodetail.service";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.css"],
})
export class ContactUsComponent implements OnInit {
  private thisPageSeoDetail: LinksAndMeta;
  ContactModel: ContactUsModel;
  modalRef: BsModalRef;
  errors: any[];
  errorFlag: boolean;

  @ViewChild(NgForm, { static: false }) userForm: NgForm;

  constructor(
    private Spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private sharedService: DataservicesService,
    private commonSer: CommonService,
    public bsModalRef: BsModalRef,
    private router: Router,
    private gtmService: GTMService,
    private seo: SEOService,
    private seoDetail: SEOdetailService
  ) {}

  ngOnInit() {
    this.ContactModel = new ContactUsModel();
    this.errorFlag = false;
    this.seoDetail.getThisRouteSEODetail("contactUs").subscribe((response) => {
      if (response) {
        // console.log(response);
        this.thisPageSeoDetail = response;
        this.seo.updateTitle(this.thisPageSeoDetail.PageTitle);
        this.seo.updateTag({
          name: "meta",
          content: this.thisPageSeoDetail.MetaData,
        });
        this.seo.updateTag({
          name: "keywords",
          content: this.thisPageSeoDetail.Keyword,
        });
      } else {
        console.log("contactUs SEO Response is null");
      }
    });
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    this.Spinner.hide();
    this.seo.removeTag("meta");
    this.seo.removeTag("keywords");
  }

  closeModal() {
    this.bsModalRef.hide();
    this.gtmService.pushEventTag(
      "contact-us modal unloaded",
      "support",
      "click",
      "User close contact-us dialog"
    );
  }

  sendContactUsMsg() {
    this.Spinner.show();
    this.gtmService.pushEventTag(
      "contact-us modal unloaded",
      "support",
      "click",
      "User Submit the query for support"
    );
    this.commonSer.sendContactUsMsg(this.ContactModel).subscribe(
      (res) => {
        this.Spinner.hide();
        if (res.IsSuccessfully) {
          this.errorFlag = false;
          this.sharedService.add("Message", res.Message);
          this.modalRef = this.modalService.show(AlertmodalComponent, {
            class: "modal-sm",
          });
          this.bsModalRef.hide();
        } else {
          this.sharedService.add("Message", res.Message);
          this.modalRef = this.modalService.show(AlertmodalComponent, {
            class: "modal-sm",
          });
        }
      },
      (err: HttpErrorResponse) => {
        this.Spinner.hide();
        this.sharedService.add("Contact Us", "Failed to send query");
        this.modalRef = this.modalService.show(AlertmodalComponent, {
          class: "modal-sm",
        });
        console.log(err);
      }
    );
  }

  label;
  focusFunction(f: string) {
    //$(".input-inside").addClass("filled");
    //  $(".input-inside label").css("top","0%")
    this.label = f;
    $("#" + f).css("top", "0%");
    $("#" + f).css("font-size", "12px");
    $("#" + f)
      .parent()
      .addClass("filled");
  }

  focusOutFunction(element) {
    if (element.value) {
      return;
    } else if (!element.valid && !element.pristine) {
      $("#" + this.label).css("top", "34%");
      $("#" + this.label).css("font-size", "15px");
      $("#" + this.label)
        .parent()
        .removeClass("filled");
    } else {
      $("#" + this.label).css("top", "50%");
      $("#" + this.label).css("font-size", "15px");
      $("#" + this.label)
        .parent()
        .removeClass("filled");
    }

    // if ($(".input-inside input.ng-valid").length > 0) {
    // } else {
    //   $(".input-inside").removeClass("filled");
    //   $(".input-inside label").css("top","35%")
    // }
  }
}
