import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LinksAndMeta } from 'src/app/seo/Model/linksandmeta.model';
import { SEOService } from 'src/app/seo/services/seo.service';
import { SEOdetailService } from 'src/app/seo/services/seodetail.service';

@Component({
  selector: 'app-login-default-layout',
  templateUrl: './login-default-layout.component.html',
  styleUrls: ['./login-default-layout.component.css']
})
export class LoginDefaultLayoutComponent implements OnInit {

  @ViewChild('formMain', { static: false }) fromTmplate: ElementRef; 
  private thisPageSeoDetail: LinksAndMeta;
  constructor(private seo: SEOService,
    private seoDetail: SEOdetailService) { }

  ngOnInit() {
    setTimeout(() => {
      this.fromTmplate.nativeElement.style.opacity = '1';
    }, 10);
    setTimeout(() => {
      this.fromTmplate.nativeElement.style.top = '-40px';
    }, 10);

    this.seoDetail.getThisRouteSEODetail('login')
    .subscribe(response => {
      if(response)
      {
        // console.log(response);
        this.thisPageSeoDetail = response;
        this.seo.updateTitle(this.thisPageSeoDetail.PageTitle);
        this.seo.updateTag({name: 'meta', content: this.thisPageSeoDetail.MetaData});
        this.seo.updateTag({name: 'keywords', content: this.thisPageSeoDetail.Keyword});
      }
      else{
        console.log("login SEO Response is null");
      }
    });;
  }

  ngOnDestroy(){
    this.seo.removeTag("meta");
    this.seo.removeTag("keywords");
  }
}
